/** @jsx jsx */
import React, { useReducer, useContext, useEffect } from 'react'

import Amplify from "@aws-amplify/core";
import { API, graphqlOperation, Cache } from "aws-amplify"
import { ListAllAssets, ListShotAssetsDeltasIndex, ListShotAssetsDateDeltasIndex } from "../graphql/shotassetQueries"

import Helmet from 'react-helmet'


import {loadStripe} from '@stripe/stripe-js';
// import {
//   CardElement,
//   Elements,
//   useStripe,
//   useElements,
// } from '@stripe/react-stripe-js';


import { Flex, Box, jsx } from 'theme-ui'
import { Link } from 'gatsby'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import Spinner from 'react-bootstrap/Spinner'
import ListGroup from 'react-bootstrap/ListGroup'
import ProductCard from '../@geocine/gatsby-theme-organization/components/ProductCard'
import Layout from '../@geocine/gatsby-theme-organization/components/Layout'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleLeft, faSpinner} from "@fortawesome/free-solid-svg-icons";

// import Layout from "../components/layout"
// import SEO from "../components/seo"
import awsconfig from "../aws-exports";

import 'src/css/theme.scss'
import favicon from '../@geocine/gatsby-theme-organization/assets/favicon.ico'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { config } from '@fortawesome/fontawesome-svg-core'

import { AppsContext } from '../AppsProvider'

// Prevent fontawesome from dynamically adding its css since we did it manually above
config.autoAddCss = false



// http://d1w2buicv2uw3m.cloudfront.net/V2/6B3E1A00BDAB94E4DA166A30A759D44C/assets/9519550.jpg
Amplify.configure(awsconfig); // Configure Amplify

const colorWhite50 = "rgba(255,255,255, .5)"
const colorBlack50 = "rgba(0,0,0, .5)"
const colorBlack80 = "rgba(0,0,0, .8)"
const colorWhiteHover = "rgba(255,255,255, .7)"
const colorSubLinkBackground = "rgba(0,0,0, .1)"

const linkStyle = {
  color: 'black',
	float: 'left',
	padding: '8px 16px',
	textDecoration: 'none',
	transition: 'backgroundColor .3s',
	':hover': '',
}


var stripePromise, checkoutSessionId

// const CheckoutForm = () => {
//   const stripe = useStripe();
//   const elements = useElements();

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     const {error, paymentMethod} = await stripe.createPaymentMethod({
//       type: 'card',
//       card: elements.getElement(CardElement),
//     });
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <CardElement />
//       <button type="submit" disabled={!stripe}>
//         Pay
//       </button>
//     </form>
//   );
// };



  async function checkoutSession(sessId) {
    // console.log('image:', appsContext.state.videoPlayer.image)
    
      try{

        
        
        var urlParams = new URLSearchParams(window.location.search);
        var sessionId = urlParams.get("session_id")
        if (sessionId) {

            const session = await API.get('shotwireAPI', '/checkout-session', 
                            {
                              'queryStringParameters': {
                                'sessionId': sessId,
                                // 'jobTemplate': 'Shotwire-Watermarked-Proxies'                      
                              }
                            });
            console.log('session:', session)
            return session


          // fetch("/checkout-session?sessionId=" + sessionId).then(function(result){
          //   return result.json()
          // }).then(function(session){
          //   var sessionJSON = JSON.stringify(session, null, 2);
          //   document.querySelector("pre").textContent = sessionJSON;
          // }).catch(function(err){
          //   console.log('Error when fetching Checkout session', err);
          // });
        }
        // console.log('createProxy:', createProxy)
        // return createProxy
        // add video to delta table once proxy is created
        // appsContext.dispatch({type:"CREATEVIDEOPROXY", newAsset:{ name:srcObj.name, fileName: srcObj.name, archived: 0, assetType: 3, s3srcUrl: srcObj.srcUrl, proxyInfo: 'pending' } })
      // appsContext.addAssetDelta({ name:srcObj.name, fileName: srcObj.name, archived: 0, assetType: 3, s3srcUrl: srcObj.srcUrl, proxyInfo: 'pending' })
     
        
      }catch(err){
        //add an image placehold for assets with no image
        // dispatch({ type: "QUERY", albums: [{id:`none`,name:`none`}] });
        console.log('error: ', err)
      }
      

  }

var setupElements = async function() {
  stripePromise = await loadStripe("pk_live_xVFKV4FGy3ycA15Ft5vPBZDl");
  // console.log('stripePromise:', stripePromise)
  // const pubkey = await API.get('shotwireAPI', '/stripe-pub-key', 
  // // {
  //                       // 'queryStringParameters': {
  //                       //   // 'setId': appsContext.state.videoPlayer.assetObj.realSetId,
  //                       //   // 'setDate': appsContext.state.videoPlayer.assetObj.setDate,
  //                       //   // 'setName': appsContext.state.videoPlayer.assetObj.name,
  //                       //   // 'assetName': (dlType === 'hrdl') ? appsContext.state.videoPlayer.assetObj.highRezFileName : appsContext.state.videoPlayer.assetObj.highRezFileName.replace('.mp4','-h264.mp4'),
  //                       //   'jobAction': 'createProxy',
  //                       //   's3srcUrl' : srcObj.srcUrl,
  //                       //   'assetName' : srcObj.name,
  //                       //   'jobTemplate': 'Shotwire-Watermarked-Proxies'                      
  //                       // }
  //                     // }
  //                     );
  //       console.log('pubkey:', pubkey)
  //       return pubkey

  // fetch("/public-key", {
  //   method: "GET",
  //   headers: {
  //     "Content-Type": "application/json"
  //   }
  // })
  //   .then(function(result) {
  //     return result.json();
  //   })
  //   .then(function(data) {
  //     stripe = Stripe(data.publicKey);
  //   });
};

async function createCheckoutSession(planId) { 
  let apiName = 'shotwireAPI'; // replace this with your api name.
  let path = '/create-checkout-session'; //replace this with the path you have configured on your API
  let myInit = {
      body: {
        planId: planId
      }, // replace this with attributes you need
      headers: {} // OPTIONAL e.g. "Content-Type": "application/json"
  }

    await API.post(apiName, path, myInit).then(response => {
      // Add your code here
      // console.log('response:',response)
      return response.checkoutSessionId;
    }).then(sessId => {
        // Add your code here
        // console.log('sessId:',sessId)
        checkoutSessionId = sessId;

        stripePromise
          .redirectToCheckout({
            sessionId: sessId
          })
          .then(function(result) {
            // console.log('stripe result:', result)
            // console.log("error");
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
          })
          .catch(function(err) {
            // console.log('stripe err:',err);
          });


    }).catch(error => {
        // console.log('sess error:',error)
    });
    // return sess
}


// var createCheckoutSession = function(isBuyingSticker) {

//   let apiName = 'shotwireAPI'; // replace this with your api name.
//   let path = '/create-checkout-session'; //replace this with the path you have configured on your API
//   let myInit = {
//       body: JSON.stringify({ isBuyingSticker }),//{}, // replace this with attributes you need
//       headers: {"Content-Type": "application/json"} // OPTIONAL
//   }

//   API.post(apiName, path, myInit).then(response => {
//       // Add your code here
//       console.log('response:',response)
//       return response.json();
//   }).then(data => {
//       // Add your code here
//       console.log('data:',data)
//       checkoutSessionId = data.checkoutSessionId;
//   }).catch(error => {
//       console.log(error.response)
//   });

//     const checkoutSession = await API.post('shotwireAPI', '/create-checkout-session', 
//     {
//                         // 'queryStringParameters': {
//                         //   // 'setId': appsContext.state.videoPlayer.assetObj.realSetId,
//                         //   // 'setDate': appsContext.state.videoPlayer.assetObj.setDate,
//                         //   // 'setName': appsContext.state.videoPlayer.assetObj.name,
//                         //   // 'assetName': (dlType === 'hrdl') ? appsContext.state.videoPlayer.assetObj.highRezFileName : appsContext.state.videoPlayer.assetObj.highRezFileName.replace('.mp4','-h264.mp4'),
//                         //   'jobAction': 'createProxy',
//                         //   's3srcUrl' : srcObj.srcUrl,
//                         //   'assetName' : srcObj.name,
//                         //   'jobTemplate': 'Shotwire-Watermarked-Proxies'                      
//                         // }
//                       });

//         console.log('checkoutSession:', checkoutSession)
//         return checkoutSession

//         fetch("/create-checkout-session", {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json"
//             },
//             body: JSON.stringify({ isBuyingSticker })
//           })
//             .then(function(result) {
//             return result.json();
//           })
//           .then(function(data) {
//             checkoutSessionId = data.checkoutSessionId;
//           });
//   };

const CheckoutCancelPage = () => {
  // let stripe = null 
  let session = null

  const appsContext = React.useContext(AppsContext)

  // const stripeInit = async () => {
  //   stripe = await loadStripe('pk_test_8iiUqRzMdP7ipyKMn1wHDhyF');
  //   const stripePromise = loadStripe('pk_test_JJ1eMdKN0Hp4UFJ6kWXWO4ix00jtXzq5XG');
  //   console.log('stripe:', stripe)
  //   appsContext.dispatch({ type: "STRIPE", stripe })
    
  
  // }

  // const stripeSession = async (planId) => {
  //   console.log('stripePromise...', stripePromise)
  //   session = await stripePromise.checkout.sessions.create({
  //     // customer_email: 'customer@example.com',
  //     payment_method_types: ['card'],
  //     subscription_data: {
  //       items: [{
  //         plan: planId,
  //       }],
  //       trial_from_plan: true,
  //     },
  //     success_url: 'https://video2020.shotwire.com/buysccss',
  //     cancel_url: 'https://video2020.shotwire.com/buycncl',
  //   });

  //   return session
    
  // }

  // (async () => {
  //   const session = await stripe.checkout.sessions.create({
  //     customer_email: 'customer@example.com',
  //     payment_method_types: ['card'],
  //     subscription_data: {
  //       items: [{
  //         plan: 'plan_123',
  //       }],
  //     },
  //     success_url: 'https://example.com/success',
  //     cancel_url: 'https://example.com/cancel',
  //   });
  // })();


  
	useEffect(() => {
		      try{
           if(appsContext.state.user){
            const  userInfo  = appsContext.state.user
            // console.log('userInfo: ', userInfo)
            const uRoles = userInfo.attributes[`custom:roles`].split(',')
            //user roles checks
            if( uRoles.some(r => ['videoAdmin','superAdmin'].indexOf(r) >= 0) ) appsContext.dispatch({ type: "ISADMIN", isAdmin:true })  // checks for one or more permissions to allow access to specific components
          }

          setupElements();
          // createCheckoutSession();
          // stripeInit()
         // console.log('appsContext.state.currentCategory:', appsContext.state.currentCategory)
              // console.log('appsContext.state.categoryFilter:', appsContext.state.categoryFilter)
              // console.log('appsContext.state.categoryMetaField:', appsContext.state.categoryMetaField)
              // getCachedAssetData()
              if( appsContext.state.isAdmin ){
                // console.log('live data...')
                // getAssetSearchData()
              }
              // getData();
              
              // const subscription = API.graphql(graphqlOperation(onCreateAlbum)).subscribe({
               //    next: eventData => {
               //      const album = eventData.value.data.onCreateAlbum;
               //      dispatch({ type: "SUBSCRIPTION", album });
               //    }
               //  });
                return () => {
                  //console.log('state2: ', state)
                  // subscription.unsubscribe();
                  
                };
          } catch(error) {
            // console.log('useEffect error:',error)
          }
		}, [ ]);







  return(
  	
<Layout>
  <Helmet>
    <title>Shotwire Purchase Cancelled</title>
    <link rel="icon" href={favicon} type="image/x-icon" />
    
  </Helmet>

 <Container>
 <Row>
 <div sx={{
  paddingLeft: '10px',
  paddingTop: '20px'
 }}>
 <h2>Sorry, Your Shotwire Purchase Was Cancelled.</h2>
 
 </div>
 </Row>
 <Row>
 <Col sx={{
  // paddingLeft: '10px',
  // padding: '20px 0px 20px',
  marginTop: '20px',
  marginBottom: '20px',
  fontSize: '1.5rem'

 }}>
 <p>
    </p>
  <p>
  </p>
 </Col>
 </Row>

<Row>
       

        </Row>
 

      </Container>
 </Layout>
 )
}


// <Layout>
//     <SEO title="Celebrity Candids" />
//     <h1>Hi from the second page</h1>
//     <p>Celebrity Candids</p>
//     <Link to="/">Go back to the homepage</Link>
//   </Layout>

export default CheckoutCancelPage